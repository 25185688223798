<script setup>
import { inject } from 'vue'

const store = inject('store')

</script>

<template>
  <div class="table-responsive">
    <table class="table table-dark table-striped text-center text-grey">
      <thead>
        <tr>
          <th class="text-muted text-uppercase" scope="col">
            <span class="fs-4 fw-bold text-light">{{ store.state.features.name }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-uppercase fw-bold text-grey">
            <span class="h5 fw-bold text-light">{{ store.state.features.fee }}</span><br>
            <span><small>{{ store.state.features.freefirst }}</small></span><br>
            <span><small>{{ store.state.features.nocontract }}</small></span><br>
            <span><small>{{ store.state.features.noadvance }}</small></span>
          </td>
        </tr>
        
        <tr>
          <td class="text-uppercase text-grey fw-bold">
            <span class="h5 fw-bold text-light">Contact tutor</span><br>
            <div class="small">
              <span>{{ store.state.tutor.name }}</span><br>
              <span>{{ store.state.tutor.pg }}</span><br>
              <span class="text-white">{{ store.state.brand.email1 }}</span>
              <span>{{ store.state.brand.email2 }}</span>
              <span class="text-white">{{ store.state.brand.email3 }}</span>
              <span class="text-secondary">{{ store.state.brand.domain }}</span>
            </div>
          </td>
        </tr>

        <tr>
          <td class="bg-black py-2">
            <a :href="`${store.state.brand.mail_to}`" type="button" class="btn btn-secondary w-50 text-uppercase">Email Leo</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
